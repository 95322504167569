<template>
  <div class="edit">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div class="content">
      <div class="test-title">
        <strong>基本资料信息</strong>
      </div>
      <div class="form">
        <div class="inputList">
          <div class="title">
            省份
            <span class="spl">*</span>
          </div>
          <div class="input area">
            <select class="select" v-model="provinceId">
              <option value="0">请选择</option>
              <option
                v-for="(item, index) in provinceArr"
                :key="index"
                :value="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="inputList">
          <div class="title">
            城市
            <span class="spl">*</span>
          </div>
          <div class="input area">
            <select class="select" v-model="cityId">
              <option value="0">请选择</option>
              <option
                v-for="(item, index) in cityArr"
                :key="index"
                :value="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
          </div>
        </div>
        <div class="inputList">
          <div class="title">
            县/区
            <span class="spl"></span>
          </div>
          <div class="input area">
            <select class="select" v-model="countyId">
              <option value="0">请选择</option>
              <option
                v-for="(item, index) in countyArr"
                :key="index"
                :value="item.area_id"
              >
                {{ item.area_name }}
              </option>
            </select>
          </div>
        </div>
        <div
          v-if="list.showOption"
          class="inputList"
          v-for="(list, index) in userInforList"
          :key="index"
          :class="{ on: list.type == 'textarea' }"
        >
          <div class="title">
            <span v-if="list.showOption">{{ list.field }}</span>
            <span v-if="list.is_must == 1 && list.showOption" class="spl"
              >*</span
            >
          </div>
          <!--判输入框样式start-->
          <div class="input">
            <!-- <edit-div class="textarea" v-if="list.type == 'textarea'" v-model="form[list.code]"></edit-div> -->

            <!--多行文本-->
            <div v-if="list.type == 'textarea'">
              <div class="mtext">
                <label
                  ><input type="radio" v-model="form[list.code]" value="" />
                  没有</label
                >
                <label
                  ><input type="radio" v-model="form[list.code]" value="1" />
                  有</label
                >
              </div>
              <textarea
                class="textarea"
                v-show="form[list.code] == 1"
                v-model="list.median"
                rows="4"
              ></textarea>
            </div>
            <!--选择日期-->
            <div v-else-if="list.type == 'date'">
              <div style="margin-bottom: 10px">
                <el-radio v-model="dateType[list.code]" @change="changeRadio(list.code)" label="select"
                  >日历选择</el-radio
                >
                <el-radio v-model="dateType[list.code]" @change="changeRadio(list.code)" label="input"
                  >手动输入</el-radio
                >
              </div>
              <date-picker
                v-if="dateType[list.code] == 'select'"
                :option="option"
                style="border: none"
                class="db-bottom"
                field="birthday"
                v-model="form[list.code]"
                placeholder="选择日期"
                format="yyyy-mm-dd"
                :backward="false"
                :no-today="false"
                :forward="false"
                @input="changeDate($event, list.code)"
              ></date-picker>

              <div v-else class="baby-birthday">
                <label for="">
                  <input
                    type="number"
                    v-model="dateInput[list.code].year"
                    min="0"
                    @change="changeInput(list.code, 'year')"
                  />年
                </label>
                <label for="">
                  <input
                    type="number"
                    v-model="dateInput[list.code].month"
                    min="0"
                    @change="changeInput(list.code, 'month')"
                  />月
                </label>
                <label for="">
                  <input
                    type="number"
                    v-model="dateInput[list.code].day"
                    @change="changeInput(list.code, 'day')"
                    min="0"
                  />日
                </label>
              </div>
              <!--<input v-if="radio==2" type="text" placeholder="格式:xxxx-xx-xx" style="width: 100px;">-->
            </div>
            <!--下拉选择框-->
            <div v-else-if="list.type == 'select'">
              <div v-if="list.id != 30">
                <div v-if="list.showOption">
                  <select
                    class="select"
                    name="select"
                    v-model="form[list.code]"
                  >
                    <option disabled value="">请选择</option>
                    <option
                      v-for="(item, index) in list.content"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <div
                    class="custom"
                    v-if="list.has_other == 1 && form[list.code] == '其他'"
                  >
                    <p>您需要在选择「其他」时输入这个栏位</p>
                    <input type="text" v-model="list.median" />
                  </div>
                </div>
              </div>
              <div v-else>
                <select class="select" name="select" v-model="infant">
                  <option disabled value="">请选择</option>
                  <option
                    v-for="(item, index) in list.content"
                    :key="index"
                    :value="item"
                  >
                    {{ item }}
                  </option>
                </select>
                <div
                  class="custom"
                  v-if="list.has_other == 1 && form[list.code] == '其他'"
                >
                  <p>您需要在选择「其他」时输入这个栏位</p>
                  <input type="text" v-model="list.median" />
                </div>
              </div>
            </div>
            <!--复选-->
            <div v-else-if="list.type == 'checkbox' && list.id == 88">
              <label v-for="(item, index) in care_man.items" :key="index">
                <input
                  type="checkbox"
                  name="care_man[]"
                  :value="item.value"
                  v-model="item.checked"
                />
                <span>{{ item.value }}</span>
              </label>
              <div class="custom" v-show="care_man.items[3].checked">
                <p>您需要在选择「其他」时输入这个栏位</p>
                <input type="text" v-model="care_man.other" />
              </div>
            </div>
            <!--普通输入框-->
            <template v-else-if="list.type == 'text'">
              <input
                type="text"
                v-if="list.code == 'weeks_pregnant'"
                v-model="form[list.code]"
                @change="changeInput(list.code)"
              />
              <input
                type="text"
                v-else
                v-model="form[list.code]"
                :readonly="list.code == 'age' || list.code == 'month' || list.code == 'day'"
              />
            </template>
            <!--输入框提示-->
            <div class="markDIv">
              <!-- 2019/10/17修改文档要求将蓝色图标提示隐藏 -->
              <!-- <p class="mark">{{ list.remark }}</p>
              <el-tooltip class="item" effect="light" placement="right">
                <div style="max-width: 200px" slot="content">
                  {{ list.remark }}
                </div>
                <img
                  v-if="list.remark && list.showOption"
                  class="tipImg"
                  src="../assets/icon/tip.png"
                  alt=""
                />
              </el-tooltip> -->
            </div>
          </div>
          <!--判断输入框样式end-->
        </div>
        <div class="form-item w-g" style="padding-right: 18%">
          <div class="input">
            <button class="btn" @click="submitForm">提交</button>
          </div>
        </div>
      </div>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import FooterNav from "@/components/FooterNav";
import EditDiv from "@/components/EditDiv";
import myDatepicker from "vue-datepicker-simple/datepicker-2.vue";

export default {
  name: "user",
  data() {
    return {
      current: "evaluation",
      provinceArr: [],
      cityArr: [],
      countyArr: [],
      provinceId: 0,
      cityId: 0,
      countyId: 0,
      form: {
        is_school: "",
      },
      userInforList: [],
      box: false,
      option: {
        inputStyle: {
          width: "98%",
          height: "38px",
        },
      },
      infant: "",
      showSchool: true,
      care_man: {
        items: [
          { id: 1, value: "(外)祖父母", checked: false },
          { id: 2, value: "父母", checked: false },
          { id: 3, value: "保姆", checked: false },
          { id: 4, value: "其他", checked: false },
        ],
        other: "",
      },
      dateType: {
        exam_date: 'select',
        birthday: 'select',
        pre_born_date: 'select',
      },
      dateInput:{
        exam_date: {
          year: '',
          month: '',
          day: '',
        },
        birthday: {
          year: '',
          month: '',
          day: '',
        },
        pre_born_date: {
          year: '',
          month: '',
          day: '',
        }
      },
      year: "",
      month: "",
      day: "",
    };
  },
  methods: {
    getbaseInfo: function () {
      const token = localStorage.getItem("token");
      const id = this.$route.query.id;
      if (!token && !id) {
        this.$router.push({ path: "/login" });
      }
      this.$post("exam/baseField", { token: token, title_id: id })
        .then((res) => {
          if (res.code === 1) {
            this.userInforList = res.data;
            //循环为存在'其他'的资料选项添加一个中间字段
            this.userInforList.forEach(item=>{
              item.showOption = true;
              let arr = item.content;
              let num = arr.length;
              if (arr.length >= 1 && arr[num - 1] == "其他") {
                item.median = "";
              }
              if (item.type === "textarea") {
                item.median = "";
              }
            })
            let _arr = {};
            res.data.map((item, index) => {
              let { code } = item;
              Object.assign(_arr, {
                [code]: "",
              });
            });
            //console.log(_arr)
            this.form = Object.assign({}, _arr);
            //console.log(this.form)
          } else {
            this.$layer.msg(res.msg);
          }
        })
        .catch((response) => {
          this.$layer.msg("网络异常，请稍后重试！");
        });
    },
    checkInfor() {
      let _arr = [];
      this.userInforList.map((item, inex) => {
        if (item.is_must == 1) {
          _arr.push({ code: item.code, field: item.field });
        }
      });
      for (let i = 0; i < _arr.length; i++) {
        if (!this.form[_arr[i].code]) {
          return `请输入${_arr[i].field}`;
        }
      }
    },
    // 如果选择其他选项/
    inspectOpt() {
      let upData = Object.assign({}, this.form);
      for (let item in this.userInforList) {
        let info = this.userInforList[item];
        if (info.type == "select") {
          if (info.median && upData[info.code] == "其他") {
            upData[info.code] = info.median;
          }
        } else if (info.id == 88) {
          let str = "";
          for (let i in this.care_man.items) {
            if (this.care_man.items[i].checked) {
              if (this.care_man.items[i].value == "其他") {
                str += `、${this.care_man.other}`;
              } else {
                str += `、${this.care_man.items[i].value}`;
              }
            }
          }
          str = str.replace(/、/, "");
          upData[info.code] = str;
          this.form[info.code] = str;
        }
        //如果是多行文本的选项
        if (info.type == "textarea") {
          if (info.median && upData[info.code] == "1") {
            upData[info.code] = info.median;
          } else {
            upData[info.code] = "无";
          }
        }
      }
      return upData;
    },
    submitForm () {
      let _self = this;
      let params = this.inspectOpt();
      if (!this.provinceId || !this.cityId) {
        this.$layer.msg("请选择所在地区");
        return;
      }
      let {birthday, exam_date} = this.form
      if (birthday.year && birthday.month && birthday.day && exam_date.year && exam_date.month && exam_date.day) {
        let date_b = this.DateBetw(birthday, exam_date)
        if (this.form.condition === '早产（怀孕期未满37周）' && date_b.years < 2) {
          if (!this.form.pre_born_date && !this.form.weeks_pregnant) {
            this.$layer.alert('小于2周岁的早产儿请输入预产期或者换孕周数！')
            return
          }

        }
      }
      let _infor = this.checkInfor();
      if (_infor) {
        this.$layer.msg(_infor);
      } else {
        params.title_id = _self.$route.query.id;
        let province = "";
        let city = "";
        let county = "";
        for (let i = 0; i < this.provinceArr.length; i++) {
          if (this.provinceArr[i].area_id == this.provinceId) {
            province = this.provinceArr[i].area_name;
          }
        }
        for (let i = 0; i < this.cityArr.length; i++) {
          if (this.cityArr[i].area_id == this.cityId) {
            city = this.cityArr[i].area_name;
          }
        }
        for (let i = 0; i < this.countyArr.length; i++) {
          if (this.countyArr[i].area_id == this.countyId) {
            county = this.countyArr[i].area_name;
          }
        }
        params.province = province;
        params.city = city;
        params.district = county;

        // let params = Object.assign(this.form, {
        //   title_id: _self.$route.query.id
        // });
        // console.log("基本资料", params);
        this.$post("exam/saveBaseInfo", params)
          .then((res) => {
            this.$layer.msg(res.msg);
            if (res.code === 1) {
              localStorage.setItem("eid", res.data.exam_id);
              localStorage.setItem("tid", res.data.first_ques.title_id);
              localStorage.setItem("qid", res.data.first_ques.items[0].ques_id);
              setTimeout(() => {
                this.$router.replace("/test?id=" + this.$route.query.id);
              }, 1000);
            }
          })
          .catch((response) => {
            this.$layer.msg("网络异常，请稍后重试！");
          });
      }
    },
    // 获取地区
    getArea(type, id) {
      let params = {
        area_id: id,
      };
      this.$post("index/getArea", params).then((res) => {
        if (res.code === 1) {
          if (type == "province") {
            this.provinceArr = res.data;
          } else if (type == "city") {
            this.cityArr = res.data;
          } else if (type == "county") {
            this.countyArr = res.data;
          }
        }
      });
    },
    // 响应日期选择
    changeDate(val,code) {
      let valArr = val.split('-')
      this.dateInput[code].year = valArr[0]
      this.dateInput[code].month = valArr[1]
      this.dateInput[code].day = valArr[2]
      if (this.$route.query.id == 9 && (code == 'birthday' || code == 'pre_born_date' || code == 'exam_date') ) {
        if (this.form.birthday) {
          this.computedAge()
        }
      }
    },
    // 切换清空选择
    changeRadio (code) {
      this.form[code] = ''
      this.dateInput[code].year = ''
      this.dateInput[code].month = ''
      this.dateInput[code].day = ''
    },
    changeInput(code){
      // 预产期响应
      if (code == 'weeks_pregnant') {
        this.computedAge()
        return
      }
      let { dateInput } = this
      if (dateInput[code].year && dateInput[code].month && dateInput[code].day) {
        let val = dateInput[code].year + '-' + dateInput[code].month + '-' + dateInput[code].day
        this.form[code] = val
        if (this.$route.query.id == 9 && (code == 'birthday' || code == 'pre_born_date')) {
          if (this.form.birthday) {
            this.computedAge()
          }
        }
      }
    },
    computedAge () {
      let {birthday, exam_date} = this.form
      if (!birthday || !exam_date) return
      // 评估日期
      let examTime = new Date( exam_date.replace(/-/g, '/') ).getTime()
      // 出生日期
      let ageTime = new Date( birthday.replace(/-/g, '/') ).getTime()
      if (examTime < ageTime) {
        this.$layer.msg("您请输入的出生日期有误，请重新输入！");
        return
      }
      let date_b = this.DateBetw(birthday, exam_date)
      // 年龄小于2岁需要校正
      if (date_b.years < 2) {
        // 有预产期
        let {form} = this
        if (form.pre_born_date) {
          let {dateInput} = this
          let code = 'pre_born_date'
          let ydate = dateInput[code].year + '-' + dateInput[code].month + '-' + dateInput[code].day
          date_b = this.DateBetw(ydate, exam_date)
        } else {
          // 无预产期 计算怀孕周数
          if (form.weeks_pregnant) {
            let week_hy = form.weeks_pregnant * 1
            if (week_hy < 60) {
              let dweek = 40 - week_hy
              let dmonth = parseInt(dweek / 4)
              let dday = dweek % 4 * 7
              if (date_b.months >= dmonth) {
                date_b.months -= dmonth
              } else {
                date_b.years -= 1
                date_b.months = date_b.months - dmonth + 12
              }
              if (date_b.days >= dday) {
                date_b.days -= dday
              } else {
                date_b.months -= 1
                date_b.days = date_b.days - dday + 30
              }
            } else {
              this.$layer.alert('怀孕周数输入有误，早产怀孕周不大于37周！')
              return
            }
          }
        }
      }
      // 天满15加一个月，小于15舍去
      if (date_b.days > 15) date_b.months += 1
      // 显示
      if (this.form.age !== 'undefined') {
        if (date_b.years > 0) {
          if (date_b.months>0) {
            this.form.age = date_b.years + '岁' + date_b.months + '个月'
          } else {
            this.form.age = date_b.years + '岁'
          }
        } else {
          this.form.age = date_b.months + '个月'
        }
      }
      if (this.form.month !== 'undefined') {
        this.form.month = date_b.years * 12 + date_b.months
      }
    },
    //返回两个日期相差的月数
    DateBetw(date1, date2) { //date1和date2是2019-3-12格式
        //用-分成数组
        date1 = date1.split("-");
        date2 = date2.split("-");
        //获取年,月数
        let year1 = parseInt(date1[0]),
            year2 = parseInt(date2[0]),
            month1 = parseInt(date1[1]),
            month2 = parseInt(date2[1]),
            day1 = parseInt(date1[2]),
            day2 = parseInt(date2[2]),
            //通过年,月差计算月份差
            years = 0,
            months = 0,
            days = 0;
            years = Math.abs(year2 - year1)
            months = month2-month1
            days = day2-day1
            if (month2<month1) {
              years = years - 1
              months+=12
            }
            if (day2<day1) {
              months = months - 1
              days+=30
            }
        return {
          years: years,
          months: months,
          days: days,
        };
    }
  },
  components: {
    HeaderNav,
    FooterNav,
    EditDiv,
    "date-picker": myDatepicker,
  },
  mounted: function () {
    // var h = document.documentElement.clientHeight || document.body.clientHeight;
    // this.$refs.content.style.minHeight = h - 144 + "px";
  },
  watch: {
    form: {
      handler(newVal, oldVal) {
        if (newVal.condition === '早产（怀孕期未满37周）') {
          // console.log(111, newVal.condition)
        }
      },
      deep: true
    },
    infant: function (val) {
      if (this.infant == "否") {
        this.form.is_school = "否";
        for (let item in this.userInforList) {
          let idArr = this.userInforList[item].id;
          if (
            idArr == 31 ||
            idArr == 32 ||
            idArr == 33 ||
            idArr == 34 ||
            idArr == 35 ||
            idArr == 36 ||
            idArr == 37 ||
            idArr == 38
          ) {
            this.userInforList[item].showOption = false;
          }
        }
      } else {
        this.form.is_school = "是";
        for (let item in this.userInforList) {
          let idArr = this.userInforList[item].id;
          if (
            idArr == 31 ||
            idArr == 32 ||
            idArr == 33 ||
            idArr == 34 ||
            idArr == 35 ||
            idArr == 36 ||
            idArr == 37 ||
            idArr == 38
          ) {
            this.userInforList[item].showOption = true;
          }
        }
      }
    },
    provinceId() {
      this.cityArr = [];
      this.cityId = 0;
      this.countyArr = [];
      this.countyId = 0;
      this.getArea("city", this.provinceId);
    },
    cityId() {
      this.countyArr = [];
      this.countyId = 0;
      this.getArea("county", this.cityId);
    },
  },
  created() {
    if (this.$route.query.id) {
      this.getbaseInfo();
    }
    this.getArea("province", "");
  },
};
</script>

<style scoped lang="less">
@import url("../assets/css/page/test");

.form {
  /*width: 800px;*/
  margin: 40px auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  .inputList {
    /*width: 50%;*/
    display: flex;
    margin: 4px 0;
    margin-left: 35px;

    .title {
      line-height: 42px;
      position: relative;
      display: inline-block;
      width: 322px;
      text-align: right;
      padding-right: 15px;

      .spl {
        font-size: 16px;
        color: red;
        position: absolute;
        right: 2px;
        top: 3px;
      }
    }

    .input {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 8px;

      input[type="text"] {
        width: 245px;
        height: 26px;
        box-sizing: border-box;
        padding-left: 14px;
      }

      input[type="checkbox"] {
        margin: 3px 2px 3px 10px;
        float: left;
      }

      input[type="checkbox"] + span {
        float: left;
      }

      .textarea {
        border: solid #999 1px;
        line-height: 1.6;
        padding: 10px;
        width: 117%;
      }

      .markDIv {
        width: 35%;
        position: relative;

        .mark {
          width: 100%;
          display: inline-block;
          color: #c1b8b8;
          padding: 0 10px;
          font-size: 12px;
        }

        .tipImg {
          position: absolute;
          left: 5px;
          top: 11px;
        }
      }
    }
  }

  .on {
    width: 100%;

    .input {
      .markDIv {
        width: 100px;
      }
    }
  }
}

.form-item {
  margin-bottom: 10px;
  width: 48%;
  margin: 0 1%;
  float: left;
}

.w-g {
  width: 100%;
}

.form-item .title {
  line-height: 42px;
  padding-left: 10px;
}

.form-item .input .vue-datepicker input {
  width: 98% !important;
  height: 39px !important;
}

.form-item .birth {
  display: block;
  width: 100%;
  height: 39px;
}

.form-item .input input {
  display: block;
  width: 70%;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  border: solid #dbdbdb 1px;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
}

.form-item .input .vue-datepicker {
  width: 100%;
  border: solid #dbdbdb 1px;
}

.form-item .input .vue-datepicker input {
  width: 100%;
}

.form-item .input button {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 10px;
  border-radius: 4px;
  background-color: #f39902;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 120px;
  margin: 40px auto;
  border: none;
  outline: medium;
  cursor: pointer;
  box-shadow: 0 5px 20px rgba(218, 149, 46, 0.41);
}

.db-bottom #birthday {
  width: 96% !important;
  height: 100% !important;
}

.select {
  border: 1px solid inherit;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 28px;
  width: 245px;
  background: url("../assets/img/selectbox.png") no-repeat scroll right
    center transparent;
  padding-right: 14px;
  padding-left: 14px;
}

/*清除ie的默认选择框样式清除，隐藏下拉箭头*/
.select::-ms-expand {
  display: none;
}

.custom {
  margin-top: 5px;

  input {
    height: 23px !important;
  }
}

.area {
  select {
    min-width: 136px;
    margin-right: 10px;
  }
}

.baby-birthday {
  display: flex;

  label {
    display: flex;
    align-items: center;
    input {
      height: 26px;
      line-height: 26px;
      width: 55px !important;
      margin: 0 3px;
      border: solid #dbdbdb 1px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0;
    }
  }
}
</style>
